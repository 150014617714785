.sort-by-arrows:before,
.sort-by-arrows:after {
  @apply block absolute h-0 w-0;
  border: 4px solid transparent;
  content: '';
  right: 5px;
  top: 50%;
}
.sort-by-arrows:before {
  border-bottom-color: #666;
  margin-top: -9px;
}
.sort-by-arrows:after {
  border-top-color: #666;
  margin-top: 1px;
}

.sort-by-arrows--active-top:before {
  border-bottom-color: orangered;
}

.sort-by-arrows--active-bottom:after {
  border-top-color: orangered;
}
