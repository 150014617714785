.sidebar {
  .sidebar-collapse {
    max-width: 2rem;
    transition: max-width 0.3s ease-out;
  }
  .sidebar-expand {
    max-width: 12.5rem;
    transition: max-width 0.3s ease-in;
  }
}
