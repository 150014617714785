@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;800&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
