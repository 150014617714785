.overlay {
  .spinner__small-white {
    @apply absolute;
    top: -16px;
    left: -13px;
    .spinner-element:after {
      @apply bg-white;
    }
    transform: scale(0.4);
  }
  .spinner__mini-white {
    @apply absolute;
    top: -20px;
    left: -27px;
    .spinner-element:after {
      @apply bg-white;
    }
    transform: scale(0.25);
  }
  .spinner__big {
    .spinner-element:after {
      @apply bg-purple-100;
    }
  }

  .spinner__small {
    .spinner-element:after {
      @apply bg-purple-100;
    }
    transform: scale(0.3);
  }

  .spinner {
    @apply inline-block;
    width: 80px;
    height: 80px;
    .spinner-element {
      transform-origin: 40px 40px;
      animation: spinner 1.2s linear infinite;
    }
    .spinner-element:after {
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
    }

    .spinner-element:after {
      @apply absolute block rounded-100;
      content: '';
    }

    .spinner-element:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    .spinner-element:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    .spinner-element:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    .spinner-element:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    .spinner-element:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    .spinner-element:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    .spinner-element:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    .spinner-element:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    .spinner-element:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    .spinner-element:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    .spinner-element:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    .spinner-element:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }

  @keyframes spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
