@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply h-full m-0 overflow-auto;
}

a {
  @apply break-words;
}
